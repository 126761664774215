html,
body,
section,
div,
h1 {
  scroll-behavior: smooth;
  font-family: "Josefin Sans", sans-serif;
  color: black;
}

.page {
  position: absolute;
  left: 0;
  right: 0;
}

.particles-js {
  background-color: white;
  position: fixed;
  left: 0;
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

:hover {
  color: rgb(75, 68, 68);
}

.iconBox {
  justify-content: center;
  margin: 20px;
  padding: 5px;
}

.iconBox :hover {
  color: grey;
  transition: color 300ms linear;
}

header {
  font-family: "Josefin Sans", sans-serif;
  color: rgb(166, 165, 231);
  font-size: 18px;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 2px;
  z-index: 99;
  transition: background-color 0.5s ease-out;
}

header ul a {
  font-family: "Josefin Sans", sans-serif;
  color: rgb(134, 157, 192);
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.5s ease-out;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  background-color:grey;
}

section {
  font-family: "Josefin Sans", sans-serif;
  padding: 60px 0;
  height: 100%;
  position: relative;
}

.home {
  font-family: "Josefin Sans", sans-serif;
  color: rgb(151, 104, 104);
  padding: 0;
  left: 0;
  right: 0;
  margin-top: 8rem;
  margin-bottom: 5rem;
  text-align: center;
  justify-content: center;
}

.home h1 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  text-shadow: 0 15px 20px #808080;
}

.home h3 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
  text-align: center;
  padding: 10px 20px;
  margin: 10px 0 50px;
  display: inline-block;
  letter-spacing: 2;
}

.section-title {
  font-family: "Josefin Sans", sans-serif;
  padding-top: 10px;
  text-align: center;
  color: rgb(61, 55, 55);
}

.section-title h2 {
  font-family: "Josefin Sans", sans-serif;
  display: inline-block;
  font-size: 28px;
  border-bottom: 1px solid;
  text-transform: uppercase;
}

.section-info {
  font-family: "Josefin Sans", sans-serif;
  width: 60%;
  margin: 0 auto;
  padding: 50px 0;
  color: #3d3434;
  text-align: center;
}

.section-info p {
  font-family: "Josefin Sans", sans-serif;
  line-height: 28px;
  font-size: 24px;
}

.section-info .mybtn {
  font-family: "Josefin Sans", sans-serif;
  display: inline-block;
  padding: 10px 25px;
  background-color: #ffffff40;
  border: 1px solid #ffffff80;
  border-radius: 50px;
  color: rgb(175, 161, 161);
  font-size: 22px;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 35px;
  z-index: 999;
}
.section-info a{
  color: rgb(245, 32, 131);
}

.mynav {
  background-color: rgb(232, 236, 236);
}

/********** section 5 **************/

.section-5 {
  padding-top: 15vmin;
  padding-bottom: 10vmin;
  background-position: center;
  background: var(--bggradient);
  margin: auto;
}

.contact {
  width: 100%;
  padding: 4vmin;
  padding-top: 2vmin;
  margin-left: 30px;
  box-sizing: border-box;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  float: right;
  border-radius: 25px;
  background-color: white;
}

.contact h2 {
  color: #666;
  text-align: center;
  font-size: 30px;
}
.input-field {
  position: relative;
  width: 100%;
  margin-top: 50px;
}
.input-field input,
.input-field textarea {
  width: 100%;
  padding: 5px 0;
  box-sizing: border-box;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 2px solid #666;
  font-size: 12px;
  /* color: whitesmoke; */
  font-weight: 500;
  resize: none;
}
.input-field label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px 0;
  pointer-events: none;
  font-size: 12px;
  color: #666;
  font-weight: 700;
  transition: 0.5s;
}
.input-field input:focus + label,
.input-field textarea:focus + label,
.input-field input:valid + label,
.input-field textarea:valid + label {
  top: -25px;
  font-size: 14px;
  padding: 2px 5px;
  background: #ff006a;
  color: #fff;
  border-radius: 4px;
}
.section-5 .contact .btn {
  margin-top: 10px;
  background: #ff006a;
  color: #fff;
  padding: 12px 24px;
  border: none;
  outline: none;
  border-radius: 30px;
  font-weight: 500;
  font-size: 16px;
  transition: 0.5s;
}
.section-5 .contact .btn:hover {
  background: #dd2476;
}

.quote {
  height: auto;
  margin-left: auto;
  margin-right: 40px;
  padding-top: 5em;

  text-align: center;
  color: #808080;
  padding-top: 5em;
  font-weight: 500;
  font-size: 16px;
}

.footer {
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms linear 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms linear;
}
img.tech-icon{
  height: 50%;
  width: 50%;

}